.quote{
    display: flex;
    margin: 5em;
    text-align: -webkit-center;
    place-content: center;
}

.text h1{
    font-size: 200%;
}

.text{
    padding: 1rem;
    max-width: 450px;
    margin-left: 10rem;
}

.img{
    display: inline-block;
}


@media only screen and (max-width:800px){

    .quote{
        margin: 5em 0;
        display: block;
    }

    .text{
        margin:0
    }

}