.rainbow{
    background-image: -webkit-gradient( linear, left top, right top, color-stop(0, red), color-stop(0.2, orange), color-stop(0.4, yellow), color-stop(0.6, green), color-stop(0.8, blue), color-stop(1, purple) );
    background-image: gradient( linear, left top, right top, color-stop(0, red), color-stop(0.2, orange), color-stop(0.4, yellow), color-stop(0.6, green), color-stop(0.8, blue), color-stop(1, purple) );
    -webkit-background-clip: text;
    color: white;
    background-clip: text;
    transition: color 1s
}

.rainbow:hover{
    color: transparent
}

.page{
    display: initial;
    color: white;
}

.rhein{
    background: var(--default) url("/assets/img/rhein.webp") no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    height: 70vh;
}

.noWebp.rhein{
    background: var(--default) url("/assets/img/rhein.jpg") no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
}

.strassburg{
    background: var(--default) url("/assets/img/strassburg.webp") no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
}

.noWebp.strassburg{
    background: var(--default) url("/assets/img/strassburg.jpg") no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
}


.splash .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #111;
    opacity: .7;
}

hr{
    border:none;
}

.splash .content{
    position: sticky;
    vertical-align: middle;
    display: table-cell;
}

.image{
    width: 100%;
    position: relative;
    text-align: center;
    color: white;
    display: table;
}

.splash.mobile{
    padding-top: 5em;
    padding-bottom: 5em;
    height: fit-content;
}

.splash.noMobile{
    height: 100vh;
}

.splash {
    background-color: var(--default);
    text-align: center;
    display: table;
}

.splash .button{
    display: inline-flex;
    margin: 1rem;
}

.row{
    display: inline-flex;
}

.title{
    padding-bottom: 2rem;
    text-align: center;
}

.title span,.title h2{
    padding-top: 1rem;
    margin: 0;
}

.title h2{
    text-transform: uppercase;
}

@media only screen and (min-width:1064px){
    .smallDisplay{
        display: none;
    }

    .bigDisplay{
        display: inherit;
        font-size: 200%;
    }

    .title span{
        font-size: 400%;
    }
}

@media only screen and (max-width:1064px) and (min-width:864px){

    .bigDisplay{
        display: inherit;
        font-size: 150%;
    }

    .smallDisplay{
        display: none;
    }

    .title span{
        font-size: 300%;
    }

}

@media only screen and (max-width:864px) and (min-width:555px){
    .bigDisplay{
        display: none;
    }

    .smallDisplay{
        display: inherit;
        font-size: 150%;
    }

    .title span{
        font-size: 200%;
    }
}

@media only screen and (max-width:555px){
    .bigDisplay{
        display: none;
    }

    .smallDisplay{
        display: inherit;
        font-size: 170%;
    }

    .title span{
        font-size: 170%;
    }

    .row{
        flex-wrap: wrap;
        justify-content: center;
    }
}
